import React, { useState } from 'react'
import { motion } from "framer-motion"
import { useInView } from 'react-intersection-observer'


import "./card.scss"

import {useTranslation} from 'gatsby-plugin-react-i18next';

export default function Card(props) {


    const { t } = useTranslation()

    return (
        
        <div className="outer-container">

            <div className="card-container">

                <span className="card-text">"The costs of investing in key financial products, such as UCITS funds, remain high and diminish the investment outcome for final investors."</span>

                <span className="card-subtitle">ESMA - {t('card_footer')}</span>

            </div>
        </div>
    )



}