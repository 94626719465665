import React from "react";
import Layout from "../components/layout/layout";
import SEO from "../components/seo/seo";
import Hero from "../components/hero/hero"
import Block from "../components/block/block"
import Cta from "../components/cta/cta"
import BottomImage from "../components/bottomImage/bottomImage"
import Card from "../components/card/card";
import img2 from "../images/immagine2.png"
import Value from "../components/value/value"
import report from "../images/report.png"
import english from "../images/immagine7.png"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {graphql} from 'gatsby';
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import BlockLottie from "../components/blockLottie/blockLottie"
import reportEnglish from "../images/group15.png"

import animationIt from "../images/GraficoCommissioni_IT.json"
import animationEn from "../images/GraficoCommissioni_EN.json"






let block1 = {
  direction: "left",
  /* title: "Focus sui costi", */
  /* subtitle: "Numerose <a href='https://www.morningstar.com/articles/752485/fund-fees-predict-future-success-or-failure' target='_blank'>ricerche</a> hanno dimostrato come il livello delle commissioni sia uno dei maggiori indicatori predittivi del ritorno di un fondo. Optipholio è il frutto di anni di ricerca e della convinzione che questo elemento non possa essere trascurato durante il processo di selezione fondi.", */
}


let block2 = {
  direction: "right",
  /* title: "Analizza i portafogli in pochi click.",
  subtitle: "Carica i portafogli dei tuoi Clienti o Prospect in pochi click, analizza le inefficienze di costo presenti e l'effetto che queste hanno nel lungo periodo. Scopri i fondi ottimizzabili e quali sono le alternative, scegliendo tra fondi a gestione attiva o passiva. Infine, visualizza l'impatto che i cambiamenti apportati hanno sul portafoglio.", */
  img: [img2, english]
}


let block3 = {
  direction: "left",
  /* title: "Coinvolgi i tuoi Clienti.",
  subtitle: "Presenta i risultati ai tuoi Clienti. Con Optipholio hai a disposizione una soluzione in white-label e potrai condividere il risultato della tua analisi su pagina web interattiva o in formato cartaceo.</br> Sei tu il protagonista, non noi. ", */
  img: [report, reportEnglish]
}


let cta2 = {
  /* subtitle: "E' stato <a href='https://www.tandfonline.com/doi/abs/10.2469/faj.v56.n1.2327' target='_blank'>dimostrato</a> che l'asset allocation può pesare fino al <strong>90%</strong> nel determinare il ritorno di un investimento. </br> Sei tu, come professionista, con i tuoi consigli, a fare la differenza. </br> Optipholio ti aiuta ad identificare, a valle, gli strumenti più performanti in termini di costo, per implementare le scelte che hai condiviso con il tuo Cliente, con un unico obiettivo: accrescere il Suo Patrimonio nel tempo.", */
  smallTitle: "<a class='hero-btn' href='https://calendly.com/optipholio-demo/30min' target='_blank'>Prenota una Demo</a>"
}



const IndexPage = () => {

  const {t} = useTranslation()

  const context = React.useContext(I18nextContext);
  let currentLanguage = context.language

  

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: (currentLanguage === 'it') ? animationIt : animationEn,
    rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
    }
  }
  

  return (

    <Layout>
      <SEO title="Optipholio" />
      <Hero></Hero>

      <div id="product">
        <BlockLottie direction={block1.direction} title={t('block1_title')} subtitle={t('block1_sub_title')} animationLottie={defaultOptions} ></BlockLottie>
        <Block direction={block2.direction} title={t('block2_title')} subtitle={t('block2_sub_title')} img={(currentLanguage === 'it') ? block2.img[0] : block2.img[1]} ></Block>
        <Block direction={block3.direction} title={t('block3_title')} subtitle={t('block3_sub_title')} img={(currentLanguage === 'it') ? block3.img[0] : block3.img[1]} ></Block>
      </div>

      <form type="hidden" name="contact" method="POST" data-netlify="true">
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="email" />
      </form>


      <Value></Value>

      <BottomImage></BottomImage>
      <Cta subtitle={t('bottomimg_sub_title')} /* smallTitle={t('bottomimg_cta')} */></Cta>
      <Card></Card>

    </Layout>
  
);
  }

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
