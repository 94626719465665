import React, { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import table3 from '../../images/assetclassreturn3.png'
import table_english from "../../images/assetclassenglish.png"
import { I18nextContext } from 'gatsby-plugin-react-i18next'

import "./bottomImage.scss"

import {useTranslation} from 'gatsby-plugin-react-i18next';

export default function Card(props) {

    const context = React.useContext(I18nextContext);
    let currentLanguage = context.language


    const { t } = useTranslation()

    const [shown, setShown] = useState(false)
    let animation, initial;

    const [ref, inView, entry] = useInView({
        threshold: 0.2,
    })

    if (inView && !shown) {
        setShown(true);
    }


    animation = { display: 'block', opacity: shown ? 1 : 0, transform: shown ? "translateY(0px)" : "translateY(30px)" }
    initial = { display: 'none', opacity: 0, transform: "translateY(30px)" }

    return (

        <div className="outer">
            <span className="title">{t('bottomimg_title')}</span>
       
            <img src={(currentLanguage === 'it') ? table3 : table_english} className="asset-class-image"></img>
            

        </div>


    
    )

    

}

