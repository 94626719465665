import React, { useState } from 'react'
import { motion } from "framer-motion"
import { useInView } from 'react-intersection-observer'
import { Container, Row, Col } from 'react-bootstrap'
import "./cta.scss"

export default function Cta(props) {

    const [shown, setShown] = useState(false)
    let animationT, initialT, animationB, initialB;

    const [ref, inView, entry] = useInView({
        threshold: 0.5,
    })

    if (inView && !shown) {
        setShown(true);
    }


    animationT = { display: 'block', opacity: shown ? 1 : 0, transform: shown ? "translateY(0px)" : "translateY(-100px)" }
    initialT = { display: 'none', opacity: 0, transform: "translateY(-100px)" }

    animationB = { display: 'block', opacity: shown ? 1 : 0, transform: shown ? "translateY(0px)" : "translateY(100px)" }
    initialB = { display: 'none', opacity: 0, transform: "translateX(100px)" }


    return (
        <div ref={ref} className="cta">
            <Container>
                <Row>
                    <Col md="12">

                        <motion.div animate={animationT} initial={initialT} transition={{ duration: 0.5 }}>
                            <h1 className="title" dangerouslySetInnerHTML={{ __html: props.title }}></h1>
                            <h2 className="subtitle" dangerouslySetInnerHTML={{ __html: props.subtitle }}></h2>
                        </motion.div>

                        <motion.div animate={animationB} initial={initialB} transition={{ duration: 0.5 }}>
                            <h2 className="small-title" dangerouslySetInnerHTML={{ __html: props.smallTitle }}></h2>
                            
                        </motion.div>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}
