import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

import "./modalEmail.scss"

import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

export default function ModalEmail(props) {

  const { t } = useTranslation()

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="modal-title">
          {t('modal_email_title')}
        </Modal.Title>
      </Modal.Header>
      <form name="contact" method="POST" data-netlify="true">
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <Modal.Body className="modal-body-email">

          <p>{t('modal_email_introduction')}</p>
          <input type="email" name="email" />



        </Modal.Body>
        <Modal.Footer className="footer">
          <p className='footer-text'>{t('modal_email_footer')}</p>
          <Button className="modal-button" type="submit">{t('btn_email_modal')}</Button>
        </Modal.Footer>
      </form>

    </Modal>
  )
}