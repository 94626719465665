import React, { useState } from 'react'
import { motion } from "framer-motion"
import { useInView } from 'react-intersection-observer'
import { Col, Container, Row, Button } from 'react-bootstrap'
import MyBestModal from '../../components/modal/modal'

import {useTranslation} from 'gatsby-plugin-react-i18next';

import "./value.scss";


export default function Value(props) {

    const { t } = useTranslation()

    const [modalShow, setModalShow] = useState(false);


    const [shown, setShown] = useState(false);
    let animation, initial, animation2, initial2;

    const [ref, inView, entry] = useInView({
        threshold: 0,
    })

    if (inView && !shown) {
        setShown(true);
    }


    animation = { display: 'block', opacity: shown ? 1 : 0, transform: shown ? "translateY(0px)" : "translateY(-100px)" }
    initial = { display: 'none', opacity: 0, transform: "translateY(-100px)" }

    animation2 = { display: 'block', opacity: shown ? 1 : 0, transform: shown ? "translateY(0px)" : "translateY(100px)" }
    initial2 = { display: 'none', opacity: 0, transform: "translateY(100px)" }


    return (
        <div ref={ref} className="hero">
            <Container class="outer-container">
                <Container class="inner-container">
                <Row>
                    <Col md="12">

                        <motion.div animate={animation} initial={initial} transition={{ duration: 0.5 }}>
                            <h6 className="hero-text-2">
                                {t('value_title')}
                            </h6>

                        </motion.div>

                        <motion.div animate={animation2} initial={initial2} transition={{ duration: 0.8 }}>
                            <h2 className="hero-subtext-2">
                                {t('value_sub_title')}

                            </h2>
                        </motion.div>
                    </Col>
                </Row>

                <motion.div animate={animation2} initial={initial2} transition={{ duration: 0.5 }}>
                    <Row>

                        <Col md="12" className="text-center">
                            <a className="hero-btn" onClick={() => setModalShow(true)}>
                                {t('value_call_call_to_action')}
                            </a>

                            <MyBestModal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                            />


                        </Col>
                    </Row>



                </motion.div>
                </Container>
            </Container>
        </div >
    )

}
