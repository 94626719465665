import React, { useState } from 'react'
import { motion } from "framer-motion"
import { useInView } from 'react-intersection-observer'
import { Col, Container, Row } from 'react-bootstrap'

import "./block.scss"

export default function Block(props) {
    
    let { title, subtitle, img, direction } = props

    const [shown, setShown] = useState(false)
    let animationL, initialL, animationR, initialR;

    const [ref, inView, entry] = useInView({
        threshold: 0.5,
    })

    if (inView && !shown) {
        setShown(true);
    }


    animationL = { display: 'block', opacity: shown ? 1 : 0, transform: shown ? "translateX(0px)" : "translateX(-30px)" }
    initialL = { display: 'none', opacity: 0, transform: "translateX(-30px)" }
    
    animationR = { display: 'block', opacity: shown ? 1 : 0, transform: shown ? "translateX(0px)" : "translateX(30px)" }
    initialR = { display: 'none', opacity: 0, transform: "translateX(30px)" }

    return (
        <div ref={ref} className="block">
            <Container>

                <motion.div animate={(direction === "right")?animationR:animationL} initial={(direction === "right")?initialR:initialL} transition={{ duration: 0.5 }}>
                    <Row className={(direction === "right") ? "flex-column-reverse flex-md-row align-items-center" : "align-items-center"}>
                        <Col md={(direction === "right")?6:5}>
                            {(direction === "right") ? <img src={img} className="img-fluid order-sm"></img> : <><h1 className="title" dangerouslySetInnerHTML={{ __html: title }}></h1><h2 className="subtitle" dangerouslySetInnerHTML={{ __html: subtitle }}></h2></>}
                        </Col>
                        <Col classname="second" md="1"></Col>
                        <Col md={(direction === "right")?5:6}>
                            {(direction !== "right") ? <img src={img} className="img-fluid"></img> : <><h1 className="title" dangerouslySetInnerHTML={{ __html: title }}></h1><h2 className="subtitle" dangerouslySetInnerHTML={{ __html: subtitle }}></h2></>}
                        </Col>
                    </Row>
                </motion.div>
            </Container>

        </div>
    )

}
