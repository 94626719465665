import React, { useState } from 'react'
import { motion } from "framer-motion"
import { useInView } from 'react-intersection-observer'
import { Col, Container, Row } from 'react-bootstrap';
import "./hero.scss";
import {useTranslation} from 'gatsby-plugin-react-i18next'
import ModalEmail from '../../components/modalEmail/modalEmail'



export default function Hero(props) {

    const { t } = useTranslation()

    const [modalShow, setModalShow] = useState(false)

    const [shown, setShown] = useState(false)
    let animation, initial, animation2, initial2;

    const [ref, inView, entry] = useInView({
        threshold: 0,
    })

    if (inView && !shown) {
        setShown(true);
    }


    animation = { display: 'block', opacity: shown ? 1 : 0, transform: shown ? "translateY(0px)" : "translateY(-100px)" }
    initial = { display: 'none', opacity: 0, transform: "translateY(-100px)" }

    animation2 = { display: 'block', opacity: shown ? 1 : 0, transform: shown ? "translateY(0px)" : "translateY(100px)" }
    initial2 = { display: 'none', opacity: 0, transform: "translateY(100px)" }


    return (
        <div ref={ref} className="hero">
            <Container>
                <Row>
                    <Col md="12">

                        <motion.div animate={animation} initial={initial} transition={{ duration: 0.5 }}>
                            <h6 className="hero-text">
                                {t('herotitle')}
                            </h6>

                        </motion.div>

                        <motion.div animate={animation2} initial={initial2} transition={{ duration: 0.8 }}>
                            <div className="subtitle_container">
                            <h2 className="hero-subtext">
                                {t('hero_sub_title')}
                            </h2>
                            </div>  
                        </motion.div>
                    </Col>
                </Row>

                <motion.div animate={animation2} initial={initial2} transition={{ duration: 0.5 }}>
                    <Row>

                        <Col md="12" className="text-center">
                            <p className="hero-btn-subtext">{t('hero-pre-btn')}</p>
                            <a /* href="https://calendly.com/optipholio-demo/30min" target="_blank" */ className="hero-btn" onClick={() => setModalShow(true)}>
                                {t('hero-btn')}
                            </a>
                            <ModalEmail
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                            />

    

                            <p className="hero-btn-subtext2">{t('hero-post-btn')}</p>
                            <p className="hero-btn-subtext3">{t('hero-bottom')}<a href="mailto:institutional@optipholio.com">institutional@optipholio.com</a></p>
                        </Col>
                    </Row>



                </motion.div>
            </Container>
        </div >
    )

}



