import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

import "./modal.scss"

import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

export default function MyBestModal(props) {

  const { t } = useTranslation()

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="modal-title">
          {t('modal_title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('modal_introduction')}</p>
        <ul>
          <li>
            <Trans i18nKey="first_li">
              Poni l'interesse del tuo Cliente al di sopra di ogni logica commerciale o di "vendita" di prodotto. La <code>Protezione</code> e la <code>Crescita</code> del suo patrimonio sono gli unici tuoi obiettivi.
            </Trans>
          </li>
          <li>
            {t('second_li')}
          </li>
          <li>
            {t('third_li')}
          </li>
          <li>
            <Trans i18nKey="fourth_li">
              Hai una base di Clienti consolidata, ma incontri regolarmente nuovi <code>Prospects</code>, che ti richiedono (o ai quali offri) un analisi di portafoglio iniziale.
            </Trans>
          </li>
          <li>
            <Trans i18nKey="fifth_li">
              Sei estraneo alla diatriba "<code>Gestione Attiva</code>" vs. "<code>Gestione Passiva</code>", in quanto sei consapevole che non esiste il prodotto perfetto ed ogni situazione richiede una soluzione specifica.
            </Trans>
          </li>
          <li>
            {t('sixth_li')}
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer className="footer">
        <Button className="modal-button" onClick={props.onHide}>{t('btn_modal')}</Button>
      </Modal.Footer>
    </Modal>
  )
}